import React from 'react';
import { Typography, Box, Stack, Button, Link } from '@mui/material';
import config from '../../../../config';
import { getUserType } from '../../../../utils/user';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function TruzoTransactionFailed() {
  const his = useHistory();
  const userType = getUserType();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>

      <Box
        sx={{
          width: "50%",
          bgcolor: 'background.paper',
          p: 4,
        }}>
        <Box
          sx={{
            textAlign: "center",
            img: {
              width: "250px",
              height: "100%",
              objectFit: "contain"
            }
          }}>
          <img src='/images/truzo-failed.png' alt="truzo-failed" />
        </Box>
        <Box
          sx={{ my: 2 }}>
          <Typography
            id="modal-title"
            sx={{
              fontSize: "24px", // Fixed typo from 'fontsize' to 'fontSize'
              fontWeight: 600,
              lineHeight: "28.8px",
              textAlign: "center",
              color: "#1E1E1E"
            }}>
            Your Truzo Account Awaits Completion - Finalize Now!
          </Typography>
          <Typography
            id="modal-description"
            sx={{
              mt: 3,
              fontSize: "16px", // Fixed typo from 'fontsize' to 'fontSize'
              fontWeight: 400,
              lineHeight: "22.4px",
              textAlign: "center",
              color: "#757575"
            }}>
            Oops! It seems your account setup on Truzo was not completed.
            For seamless payment processing, please complete your registration on Truzo.
          </Typography>
        </Box>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          sx={{
            mt: 3,
            "& button": {
              width: "300px",
              textTransform: "none"
            }
          }}>
          <Button
            onClick={() => {
              switch (userType) {
                case 'business':
                  return his.push('/gig/dashboard');
                case 'admin':
                  return his.push('/admin/engineer/profile');
                case 'engineer':
                  return his.push('/dashboard');
                default:
                  return his.push('/unauthorised');
              }
            }}
            sx={{
              color: "#FF8008",
              textDecoration: "underline"
            }}>Return to Dashboard</Button>
          <Link
            href={config.TRUZO_ACCOUNT}
            target='_blank'>
            <Button
              variant='contained'
              sx={{ background: "#0140AA" }}>Try again</Button>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}

export default TruzoTransactionFailed;
