import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { TypographyBold, PaddedBox, CustomRow, PaddedFooter } from '../../app.styles';

import {
  setProfileError,
  setProfileSuccess,
} from '../../../store/actions/profile';
import {
  Grid,
  Button,
  Link,
  Dialog,
  Typography,
  Rating,
  TextField,
  IconButton,
  Stack,
  Tooltip,
  Alert
} from '@mui/material';
import { getQueryParams } from '../../../utils/helpers';
import {
  approveGigApplication,
  cancelFeedBackApi,
  feedBackApiApplicant,
  getGigApplicants,
  hireApplicant
} from '../../../services/gig';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import DialogTitle from '@mui/material/DialogTitle';
import { setCookie } from '../../../utils/cookie';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../../../components/footer1/footer';
import ProposalIcon from '../../../assests/ProposalIcon.svg';
import './styles.scss';
import ApplicantTable from '../../../components/GridTable/ApplicantTable';
import useGigData from '../../../hooks/useGigData';

import DescriptionIcon from '@mui/icons-material/Description';
import ChatIcon from '@mui/icons-material/Chat';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GigProposalBusiness, {
  PROPOSAL_STATUS
} from '../../../components/Proposal/GigProposalBusiness';
import GigWorkContract from '../../../components/GigWorkContract';
import config from '../../../config';

const Applicants = (props) => {
  const query = getQueryParams();
  const queryPage = query.page || 1; // active gig page
  const queryApplicantPage = query.applicantPage || 1; // active gig page
  const gigName = query.gigname;
  const history = useHistory();
  const { id: gigId } = useParams();
  const [rows, setRows] = useState([]);
  const [applicantPage, setApplicantPage] = useState(queryApplicantPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [showProposal, setShowProposal] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [dataForHire, setDataForHire] = React.useState({});
  const [checked, setChecked] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [rating, setRating] = useState(1);
  const [ratingError, setRatingError] = useState('');
  const [currentRowData, setCurrentRowData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isTruzoLinked = useSelector(state => state.profile?.basicInfo?.isTruzoLinked);
  const { data: gigData } = useGigData(gigId);

  const redirectToViewDetails = () => {
    if (currentRowData?.proposalData._id && gigId) {
      history.push(`/gig/active-posts/milestone-payment/${gigId}/${currentRowData.proposalData._id}`)
    }
  }

  const handleCloseForComplete = () => {
    setOpenComplete(false);
    cancelMethod();
    redirectToViewDetails();
  };

  const BootstrapDialogTitle = (props) => {
    const { onClose } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const onFeedbackClick = async () => {
    let feedback = document.getElementById('standard-search').value;
    let data = {
      feedback: feedback,
      rating: rating,
      isGiven: true,
      type: 'afterHire'
    };
    if (data.rating === 0) {
      return setRatingError('Please give rating before submit.');
    }

    feedBackApiApplicant(data).then((e) => {
      setOpenComplete(false);
      toast.success('Success');
      setCookie('setOpenComplete', false, 30);
    });
    redirectToViewDetails()
  };

  const cancelMethod = async () => {
    // let feedback = document.getElementById("standard-search").value;
    let data = {
      isGiven: false,
      type: 'afterHire'
    };

    cancelFeedBackApi(data).then((e) => {
      setOpenComplete(false);
    });
  };

  const handleCheck = () => {
    setChecked(!checked);
  };

  const onHireClick = async (_data) => {
    try {
      const location = props?.newGig?.objLocation;
      const data = {
        gigId: dataForHire.gigId,
        applicationId: _data.proposalData.applicationId,
        proposalId: _data.proposalData._id,
        userId: dataForHire.userId,
        engineerName: dataForHire.engineerName,
        gigName: gigName
        // endDate: '',
      };
      if (gigData?.gig?.scopeAndBudget?.startDate) {
        data.startDate = gigData?.gig?.scopeAndBudget?.startDate;
      }
      if (location) {
        data.location = {
          country: location?.country ? location.country : '',
          state: location?.state ? location.state : location.province,
          city: location?.city ? location.city : ''
        };
      }

      const res = await hireApplicant(data);
      if (res.status === 400) {
        toast.error(res.data.message)
        throw new Error(res);
      }

      socket.emit('postNotification', {
        notificationDescription: `Congratulations, you've been hired for ${gigName}`,
        userRole: ['engineer'],
        engineerId: dataForHire.userId,
        isRead: false,
        engineerProfilePic: dataForHire.profilePic,
        redirectUrl: '/active'
      });
      socket.emit('postNotification', {
        notificationDescription: `${dataForHire.engineerName} has been hired for ${gigName} by ${props.profile.basicInfo.name}`,
        userRole: ['admin'],
        isRead: false,
        redirectUrl: '',
        engineerProfilePic: dataForHire.profilePic
      });
      setConfirmOpen(false);
      setSuccessOpen(true);
      fetchData(applicantPage);
    } catch (err) {
    } finally {

    }
  };

  const headers = ['APPLICANT NAME', 'LOCATION', 'AVAILABILITY', 'RATE', 'EMAIL', 'ACTIONS'];

  const location = useLocation();

  const onAcceptAndChat = async (payload) => {
    try {
      await approveGigApplication(payload);
      const message = `/ochat?applicationId=${payload.applicationId}&gigId=${payload.gigId}`;
      history.push(message);
    } catch (err) { }
  };
  const getActions = (
    {
      message,
      userId,
      gigId,
      engineerName,
      status,
      attachment,
      coverLetter,
      profilePic,
      applicationStatus,
      applicationId
    },
    rowData
  ) => {
    return (
      <div>
        <Stack direction={'row'} justifyContent={'center'} gap={1} mb={1}>
          {applicationStatus !== 'APPROVED' && (
            <Button
              variant="contained"
              className="popup-process"
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center'
              }}
              onClick={() => {
                onAcceptAndChat({
                  gigId,
                  engineerId: userId,
                  applicationId
                });
              }}
            >
              Accept & Chat
            </Button>
          )}
          {applicationStatus === 'APPROVED' && (
            <>
              <Link
                className="message-Link"
                component="button"
                variant="body2"
                onClick={() => {
                  history.push(message);
                }}
              >
                <Tooltip title="Chat" placement="top" arrow>
                  <IconButton
                    size="small"
                    sx={{
                      width: '54px',
                      height: '28px',
                      background: '#0140AA1A',
                      '&:hover': {
                        background: '#0140AA1A'
                      },
                      borderRadius: '4px',
                      '& svg': {
                        fill: '#0140AA'
                      }
                    }}
                  >
                    <ChatIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Link>

              <Tooltip title="View Offer" placement="top" arrow>
                <IconButton
                  disabled={
                    !rowData?.proposalData ||
                    [PROPOSAL_STATUS.APPROVED, PROPOSAL_STATUS.REJECTED].includes(
                      rowData?.proposalData?.status
                    )
                  }
                  size="small"
                  sx={{
                    width: '54px',
                    height: '28px',
                    background: '#0140AA1A',
                    '&:hover': {
                      background: '#0140AA1A'
                    },
                    borderRadius: '4px',
                    '& svg': {
                      fill: '#0140AA'
                    },
                    '&:disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'all',
                      opacity:
                        !rowData?.proposalData ||
                          [PROPOSAL_STATUS.APPROVED, PROPOSAL_STATUS.REJECTED].includes(
                            rowData?.proposalData?.status
                          )
                          ? 0.6
                          : 1
                    }
                  }}
                  onClick={() => {
                    setShowProposal(true);
                    setCurrentRowData(rowData);
                  }}
                >
                  <DescriptionIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(rowData?.proposalData?.status === PROPOSAL_STATUS.APPROVED) && (
            <Tooltip title="Hire" placement="top" arrow>
              <IconButton
                disabled={!isTruzoLinked}
                variant="contained"
                size="small"
                sx={{
                  width: '54px',
                  height: '28px',
                  background: '#0140AA1A',
                  '&:hover': {
                    background: '#0140AA1A'
                  },
                  borderRadius: '4px',
                  '& svg': {
                    fill: '#0140AA'
                  },
                  '&:disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'all',
                    opacity:
                      !rowData?.proposalData ||
                        [PROPOSAL_STATUS.APPROVED, PROPOSAL_STATUS.REJECTED].includes(
                          rowData?.proposalData?.status
                        )
                        ? 0.6
                        : 1
                  }
                }}
                onClick={() => {
                  setConfirmOpen(true);
                  setDataForHire({ ...dataForHire, ...{ userId, gigId, engineerName, profilePic } });
                  setCurrentRowData(rowData);
                }}
              >
                <PersonAddAlt1Icon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <div className="business-table-cta-wrapper">
          {(!!attachment || !!coverLetter) && (
            <img
              onClick={() => {
                history.push(`/gig/active/proposal/${gigId}`, {
                  attachment: attachment,
                  coverLetter: coverLetter
                });
              }}
              className="proposal-business-cta"
              src={ProposalIcon}
            />
          )}
        </div>
      </div>
    );
  };

  const setData = (data) => {
    const rowData = [];
    const gigList = _get(data, 'engineerList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    for (let i = 0; i < gigList.length; i++) {
      const applicationId = gigList[i]._id;
      const gig = gigList[i].userId.profileData;
      // console.log("gig", gig)
      const attachment = gigList[i].attachment;
      const coverLetter = gigList[i].coverLetter;
      const applicationStatus = gigList[i].status;
      const name = `${_get(gig, 'basicInfo.title', '')} ${_get(gig, 'basicInfo.name', '')}`;
      const country = _get(gig, 'basicInfo.country', 'Not Disclose');
      const availability = _get(gig, 'myProfile.availabilityHoursPerWeek', '')
        ? `${_get(gig, 'myProfile.availabilityHoursPerWeek', '')}
       hrs/week`
        : ''
          ? _get(gig, 'myProfile.availabilityHoursPerWeek', '')
            ? `${_get(gig, 'myProfile.availabilityHoursPerWeek', '')} hrs/week`
            : ''
          : 'Not Disclosed';
      const rate = _get(gig, 'myProfile.hourlyRate', '')
        ? `${_get(gig, 'myProfile.currency', 'R')} ${_get(gig, 'myProfile.hourlyRate', '')} /hr`
        : ''
          ? _get(gig, 'myProfile.hourlyRate', '')
            ? `${_get(gig, 'myProfile.currency', 'R')} ${_get(gig, 'myProfile.hourlyRate', '')} /hr`
            : ''
          : 'Not Disclosed';
      const emailId = _get(gig, 'basicInfo.emailId', '');
      const engineerName = _get(gig, 'basicInfo.name', '');
      const userId = _get(gig, 'basicInfo.userId', '');
      const gigId = !!props.newGig.id ? props.newGig.id : gigList[i].gigId;
      const status = _get(gig, 'basicInfo.status', '');
      const profilePic = _get(gig, 'myProfile.profilePic');
      const message = `/ochat?applicationId=${applicationId}&gigId=${gigId}`;

      rowData.push([
        name,
        country,
        availability,
        rate,
        emailId,
        getActions(
          {
            message,
            userId,
            gigId,
            engineerName,
            status,
            attachment,
            coverLetter,
            profilePic,
            applicationStatus,
            applicationId
          },
          gigList[i]
        ),
        userId
      ]);
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const fetchData = async (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 10
      },
      gigId: gigId
    };
    try {
      setIsLoading(true);
      const response = await getGigApplicants(obj);
      if (response && response.status === 200) {
        setData(_get(response, 'data', {}));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(applicantPage);
  }, []);

  const handleChange = (event, value) => {
    fetchData(value);
    setApplicantPage(value);
  };

  const handleRClick = (data) => {
    console.log('data', data);
    const engineerId = _last(data);
    let redirectUrl;
    {
      location.pathname.includes('active') &&
        (redirectUrl = `/gig/active/applicants/${gigId}?page=${queryPage}&applicantPage=${applicantPage}&gigname=${gigName}`);
    }
    {
      location.pathname.includes('inact') &&
        (redirectUrl = `/gig/inact/applicants/${gigId}?page=${queryPage}&applicantPage=${applicantPage}&gigname=${gigName}`);
    }
    {
      location.pathname.includes('closed') &&
        (redirectUrl = `/gig/closed/applicants/${gigId}?page=${queryPage}&applicantPage=${applicantPage}&gigname=${gigName}`);
    }
    history.push(`/engineer/details/${engineerId}?redirectUrl=${encodeURIComponent(redirectUrl)}`);
  };

  const goBack = (e) => {
    {
      location.pathname.includes('active') && history.push(`/gig/active`);
    }
    {
      location.pathname.includes('inact') && history.push(`/gig/inactive`);
    }
    {
      location.pathname.includes('closed') && history.push(`/gig/closed`);
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  function getApplicantHeader() {
    return (
      <>
        <Button
          variant="outlined"
          className="h-color-button-outlined smallButton"
          onClick={() => {
            goBack();
          }}
        >
          {' '}
          Back{' '}
        </Button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        {`List of Applicants for ${gigName} Gig`}
      </>
    );
  }

  return (
    <>
      {/* <ApplicationOfferModal isVisible={true} onClose={()=> {}} /> */}
      <PaddedBox
        style={{
          paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
            ? '240px'
            : '0px'
            }`,
          minHeight: '580px'
        }}
      >
        <Grid container>
          <CustomRow container className="bottom-Margin">
            <TypographyBold variant="h6" component="h6">
              {getApplicantHeader()}
            </TypographyBold>
          </CustomRow>
          {
            !isTruzoLinked && <Alert
              severity='warning'
              variant='standard'
              sx={{ mt: 1, mb: 2, width: "100%" }}>You need an active Truzo account to hire an engineer. <Link href={config.TRUZO_ACCOUNT}>Link your account now.</Link></Alert>
          }
          <Grid container className="table-Padding">
            <ApplicantTable
              headers={headers}
              rows={rows}
              isLoading={isLoading}
              handleRClick={handleRClick}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(applicantPage)}
              emptyMessage="No one has applied for the Gig yet."
            ></ApplicantTable>
          </Grid>
        </Grid>
        {/* <Dialog open={confirmOpen} onClose={handleClose}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', }}
          >
            <br />
            <Typography
              sx={{ textAlign: 'center' }}
            >
              By clicking confirm you agree to hire this applicant as per the terms of engagement.
              If you would like to attach any further company specific contractual terms and conditions you may do <br /> so via the chat function.
            </Typography>
            <Typography
              sx={{ marginTop: '30px' }}
            >
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="check"
                name="checkbox"
                checked={checked}
                onClick={handleCheck}
              />
              Accept Gig Engineer <a href="https://uat.gigengineer.io/termsconditions">T&amp;Cs</a>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                marginTop: '50px',
              }}
            >
              <Button
                variant="contained"
                className="checked-Process-btn"

                disabled={!checked}
                sx={{
                  width: "140px",
                  backgroundColor: '#0140AA',
                }}
                onClick={onHireClick}
              >
                Proceed
              </Button>
              <Box
                sx={{
                  marginLeft: '20px',
                }}                        >
                <Button
                  variant="contained"
                  className="popup-cancel"
                  type="submit" sx={{
                    width: '140px',
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
            <br />
          </Box>
        </Dialog> */}
        <GigProposalBusiness
          open={showProposal}
          rowData={currentRowData}
          setOpen={setShowProposal}
          gigId={gigId}
          callback={() => {
            setCurrentRowData(undefined);
            fetchData(applicantPage);
          }}
        />

        <GigWorkContract
          showModal={confirmOpen}
          setShowModal={setConfirmOpen}
          rowData={currentRowData}
          gigData={gigData?.gig}
          callback={onHireClick}
        />

        {/* success dialog */}
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={successOpen} onClose={handleClose}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}
          >
            <br />
            <Typography sx={{ textAlign: 'center' }}>
              Congratulations you have successfully hired{' '}
              <strong>{dataForHire.engineerName}</strong> for <strong>{gigName}</strong>!
            </Typography>
            <Box
              sx={{
                marginTop: '20px'
              }}
            >
              <Button
                variant="contained"
                className="popup-cancel"
                type="submit"
                sx={{
                  width: '120px'
                }}
                onClick={() => {
                  setSuccessOpen(false);
                  setOpenComplete(true);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* feedback success */}
        <Dialog open={openComplete} onClose={handleCloseForComplete}>
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseForComplete}
            ></BootstrapDialogTitle>
            <h2>Hey there!</h2>
            <Typography>Please provide feedback on your hiring experience.</Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ marginTop: '12px' }}>
                <Rating
                  name="size-large"
                  defaultValue={rating}
                  size="large"
                  onChange={(event) => {
                    let starValue = event.target.value;
                    if (rating != parseInt(starValue)) {
                      setRating(parseInt(starValue));
                      setRatingError(null);
                    }
                    if (rating === parseInt(starValue)) {
                      setRating(0);
                      setRatingError('Please provide the rating.');
                    }
                  }}
                />
              </Typography>
              <Box sx={{ marginTop: '12px', marginLeft: '20px', fontSize: '20px' }}>
                ({rating}/5)
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '12px',
                color: 'red'
              }}
            >
              {ratingError}
            </Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '60ch' },
                marginTop: '-10px'
              }}
              autoComplete="off"
            >
              <div>
                <TextField
                  id="standard-search"
                  label="Feedback (Optional)"
                  // value={feedback}
                  type="search"
                  variant="standard"
                />
              </div>
            </Box>
            <Box
              sx={{
                textAlign: 'right'
              }}
            >
              <Button
                variant="contained"
                className="popup-process"
                type="submit"
                sx={{
                  width: '120px'
                }}
                onClick={onFeedbackClick}
              >
                Submit
              </Button>
              <br />
            </Box>
          </Box>
        </Dialog>
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
            ? '240px'
            : '0px'
            }`
        }}
      >
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applied: state.applied || {},
    error: state.profile.error,
    saveSuccess: state.profile.saveSuccess,
    newGig: state.newGig,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileError: (details) => dispatch(setProfileError(details)),
    setProfileSuccess: (details) => dispatch(setProfileSuccess(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);
